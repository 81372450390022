import { type LoaderFunctionArgs } from '@remix-run/node';
import { type MetaFunction, useLoaderData } from '@remix-run/react';
import { type ExternalScriptsHandle } from 'remix-utils/external-scripts';
import { promiseHash } from 'remix-utils/promise';
import { match } from 'ts-pattern';
import { validate as uuidValidate } from 'uuid';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import { PageManagerUtils } from '../../../src/components/PageManager/utils';
import config from '../../../src/config';
import { hubspotScripts } from '../../../src/tracking/hubspot';
import { type GamePack } from '../../../src/types/game';
import { fromDTOGamePacks } from '../../../src/utils/api-dto';
import { MediaPickPriorityFHD, MediaUtils } from '../../../src/utils/media';
import { BookDemoButton } from '../../components/AnonHeader';
import { AnonProvider } from '../../components/AnonLayout';
import { GamePackDetailsCard } from '../../components/GamePack/Details/GamePackDetailsCard';
import { GamePackDetailsSideAction } from '../../components/GamePack/Details/GamePackDetailsSideAction';
import {
  decodeShortId,
  makeAnonGamePackTagUrl,
  makeAnonPackUrl,
} from '../../components/GamePack/utils';
import { MetadataUtils } from '../../components/metadata';
import { AnonPublicLibraryLayout } from '../../components/PublicLibrary/AnonPublicLibraryLayout';
import { AnonPublicLibraryNav } from '../../components/PublicLibrary/AnonPublicLibraryNav';
import {
  type PublicLibraryBreadcrumbItem,
  PublicLibraryBreadcrumbs,
} from '../../components/PublicLibrary/PublicLibraryBreadcrumbs';
import { fetchGamePack } from '../../fetches/fetchGamePack';
import { fetchGamePacksByTag } from '../../fetches/fetchGamePacksByTags';
import { fetchMostPopularProduct } from '../../fetches/fetchMostPopularProduct';
import { fetchPage } from '../../fetches/fetchPage';

export const handle: ExternalScriptsHandle = {
  scripts: () => {
    return [...hubspotScripts()];
  },
};

function uuidValidateShort(val: string | undefined): string | undefined {
  if (!val) return;
  try {
    return decodeShortId(val);
  } catch (error) {
    return val;
  }
}

export const loader = async (action: LoaderFunctionArgs) => {
  const url = new URL(action.request.url);
  const slug = action.params.slug;
  const packId = match(slug)
    .when(
      () => !slug,
      () => undefined
    )
    .when(
      () => uuidValidate(slug?.slice(-36) ?? ''),
      () => slug?.slice(-36) ?? ''
    )
    .otherwise(() => uuidValidateShort(slug?.split('-').pop()));
  const tagSlug = url.searchParams.get('tag');
  if (!packId) throw new Response('Not Found', { status: 404 });

  const pageName = EnumsPageName.PageNameExplore;
  const { pack, mostPopularProduct, page } = await promiseHash({
    pack: fetchGamePack(packId),
    mostPopularProduct: fetchMostPopularProduct(),
    page: fetchPage(pageName),
  });

  const tags = PageManagerUtils.GetGamePackTags(page);
  const tag = tags.find((t) => t.slug === tagSlug) || pack.tags?.[0] || null;
  const relatedGamePacks = tag
    ? (await fetchGamePacksByTag(tag.id, 4))
        .filter((p) => p.id !== packId)
        .slice(0, 3)
    : [];

  return {
    pageName,
    page,
    pack,
    mostPopularProduct,
    tag,
    relatedGamePacks,
  };
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data?.pack) return [];
  const mediaFormat = MediaUtils.PickMediaFormat(data.pack.cover, {
    priority: MediaPickPriorityFHD,
  });

  return MetadataUtils.Make({
    title: data.pack.name ?? 'Experience',
    desc: data.pack.description ?? '',
    url: new URL(makeAnonPackUrl(data.pack), config.app.baseUrl).href,
    image: mediaFormat
      ? {
          url: mediaFormat.url,
          params: {
            width: mediaFormat.width,
            height: mediaFormat.height,
          },
        }
      : 'generic',
  });
};

function Breadcrumbs() {
  const { tag, pack } = useLoaderData<typeof loader>();

  const items: PublicLibraryBreadcrumbItem[] = [];
  items.push({ kind: 'link', label: 'Explore', to: '/explore' });
  if (tag) {
    items.push({
      kind: 'link',
      label: tag.name,
      to: makeAnonGamePackTagUrl(tag),
    });
  }
  items.push({ kind: 'noop', label: pack.name });

  return <PublicLibraryBreadcrumbs items={items} />;
}

function Container() {
  const data = useLoaderData<typeof loader>();
  const pack = data.pack as GamePack;

  return (
    <AnonPublicLibraryLayout
      product={data.mostPopularProduct}
      bookDemoButton={<BookDemoButton btnStyle='btn-secondary' />}
      secondaryNav={
        <AnonPublicLibraryNav
          pageName={data.pageName}
          page={data.page}
          isSectionSelected={(section) =>
            !!section.rows?.find(
              (row) => data.tag && row.id === String(data.tag.id)
            )
          }
        />
      }
      contentKey={`experiences-${pack.id}`}
    >
      <GamePackDetailsCard
        pack={pack}
        tag={data.tag}
        anonymous
        isPublic
        relatedGamePacks={fromDTOGamePacks(data.relatedGamePacks)}
        breadCrumbs={<Breadcrumbs />}
        sideAction={
          <GamePackDetailsSideAction
            pack={pack}
            product={data.mostPopularProduct}
          />
        }
      />
    </AnonPublicLibraryLayout>
  );
}

export default function Component() {
  return (
    <AnonProvider>
      <Container />
    </AnonProvider>
  );
}
