import { Link, useNavigate } from '@remix-run/react';
import { format } from 'date-fns';
import minBy from 'lodash/minBy';
import { type FormEvent, useRef, useState } from 'react';
import { $path } from 'remix-routes';

import { type DtoProduct } from '@lp-lib/api-service-client/public';
import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';

import { useAnonAppAnalytics } from '../../../../src/analytics/app/anon';
import { EMAIL_PATTERN } from '../../../../src/components/Access/types';
import { type Option } from '../../../../src/components/common/Utilities';
import { GamePackUtils } from '../../../../src/components/Game/GamePack/utils';
import { GreenCheckIcon } from '../../../../src/components/icons/GreenCheckIcon';
import { CompaniesUsingLunaParkV2 } from '../../../../src/components/Marketing/CompaniesUsingLunaPark';
import { ProductUtils } from '../../../../src/components/Product/utils';
import { useInstance } from '../../../../src/hooks/useInstance';
import { apiService } from '../../../../src/services/api-service';
import {
  HubspotSuppression,
  hubspotTrackInstantQuoteFormSubmitted,
} from '../../../../src/tracking/hubspot';
import { type GamePack } from '../../../../src/types/game';
import { formatCurrency } from '../../../../src/utils/currency';
import { useAnonFeatureChecker } from '../../hooks/useAnonFeatureChecker';
import { isGamePackLaunched } from '../utils';

const HEADCOUNT_OPTIONS_LIVE: Option<number | null>[] = [
  {
    label: '11-29',
    value: 29,
  },
  {
    label: '30-50',
    value: 50,
  },
  {
    label: '51+',
    value: null,
  },
];

function GetInstantQuoteLive(props: { pack: GamePack }) {
  const { pack } = props;

  const analytics = useAnonAppAnalytics();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const emailRef = useRef<HTMLInputElement>(null);
  const [emailErr, setEmailErr] = useState<string | null>(null);
  const [headcount, setHeadcount] = useState<Option<number | null> | null>(
    null
  );
  const [headcountErr, setHeadcountErr] = useState<string | null>(null);

  const lowestPrice = minBy(GamePackUtils.ActivePrices(pack), (p) => p.amount);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!headcount) {
      setHeadcountErr('Please select your team size');
      return;
    }
    if (!email) {
      emailRef.current?.focus();
      setEmailErr('Please enter your email');
      return;
    }
    if (!EMAIL_PATTERN.test(email)) {
      emailRef.current?.focus();
      setEmailErr('Please enter a valid email');
      return;
    }

    analytics.trackLibraryGameDetailsClicked(
      pack.id,
      pack.name,
      'See Instant Quote'
    );

    try {
      await apiService.auth.checkEmailAvailability({
        email,
      });
    } catch (error) {
      const apiErr = apiService.utils.CastAPIError(error);
      if (apiErr?.msg === 'free_email_address') {
        emailRef.current?.focus();
        setEmailErr('Please enter your work email address');
        return;
      }
    }

    hubspotTrackInstantQuoteFormSubmitted({
      email,
      headcount: headcount.label,
      kind: 'gamePack',
      gamePackName: pack.name,
    });

    const params = new URLSearchParams(window.location.search);
    params.set('register-for', 'oneTimePurchase');
    params.set('pack-id', pack.id);
    params.set('email', email);
    if (headcount.value) {
      params.set('headcount', headcount.value.toString());
    }
    navigate(`/register?${params.toString()}`);
  };

  return (
    <div className='relative w-full flex flex-col items-center gap-4'>
      <div className='absolute -top-3.5'>
        <div className='w-45 h-7 rounded-2.5xl bg-white flex justify-center items-center text-black italic'>
          Starting at {formatCurrency(lowestPrice?.amount ?? 0)} USD
        </div>
      </div>

      <form
        id='get-instant-quote-form'
        onSubmit={handleSubmit}
        className='w-full bg-main-layer rounded-xl p-2 pt-7'
      >
        <HubspotSuppression />

        <div className='text-center text-xl font-bold text-tertiary'>
          {isGamePackLaunched(pack)
            ? 'Get An Instant Quote'
            : `Launching ${format(
                new Date(pack.detailSettings?.availability?.launchDate ?? ''),
                'M/d/yy'
              )}`}
        </div>

        <div className='mt-4 w-full flex flex-col items-center'>
          <div className='text-sms text-center'>
            How many team members do you have?
          </div>

          <div className='mt-3 px-4 w-full flex items-center gap-2'>
            {HEADCOUNT_OPTIONS_LIVE.map((option) => (
              <button
                type='button'
                key={option.value}
                onClick={() => {
                  setHeadcount(option);
                  setHeadcountErr(null);
                }}
                className={`
                btn w-full h-15 rounded-xl 
                bg-layer-002 hover:bg-lp-gray-003
                border ${
                  option === headcount ? 'border-white' : 'border-secondary'
                } 
                flex items-center justify-center
            `}
              >
                <div className='text-white font-bold'>{option.label}</div>
              </button>
            ))}
          </div>
          <Link
            to={$path('/explore')}
            className='mt-2 text-3xs text-icon-gray underline'
          >
            Smaller team size? Visit our On Demand Library
          </Link>
          <div className='mt-1 px-2 text-red-006 text-3xs h-3 whitespace-nowrap'>
            {headcountErr}
          </div>
        </div>

        <div className='mt-1 w-full flex flex-col gap-1.5'>
          <div className='font-bold'>Email</div>

          <input
            ref={emailRef}
            name='email'
            type='email'
            value={email}
            onChange={(e) => {
              setEmail(e.currentTarget.value);
              setEmailErr(null);
            }}
            className={`mt-1 w-full h-12.5 m-0 transition-colors ${
              emailErr ? 'field-error' : 'field'
            }`}
            placeholder='Enter your work email address'
          />
          <div className='px-2 text-red-006 text-3xs h-3 whitespace-nowrap'>
            {emailErr}
          </div>
        </div>

        <button
          type='submit'
          className='mt-2.5 btn-delete rounded w-full h-15 flex items-center justify-center font-bold'
        >
          👉 See Instant Quote
        </button>
      </form>

      <div className='w-full flex flex-col items-center gap-2'>
        <div className='text-sms text-icon-gray italic'>
          Trusted by 90,000+ customers in 100+ countries
        </div>
        <CompaniesUsingLunaParkV2 width={300} />
      </div>
    </div>
  );
}

function GetInstantQuote(props: { pack: GamePack }) {
  const { pack } = props;

  const analytics = useAnonAppAnalytics();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const emailRef = useRef<HTMLInputElement>(null);
  const [emailErr, setEmailErr] = useState<string | null>(null);
  const [headcount, setHeadcount] = useState<number | undefined>(undefined);
  const headcountRef = useRef<HTMLInputElement>(null);
  const [headcountErr, setHeadcountErr] = useState<string | null>(null);

  const [showOnDForSmallGroups, setShowOnDForSmallGroups] = useState(false);

  const freeTrialAvailable = false;
  // !GamePackUtils.IsLive(pack) &&
  // !!headcount &&
  // headcount <= 10 &&
  // !!product?.trialPeriodDays;
  // just show the lowest price
  const lowestPrice = minBy(GamePackUtils.ActivePrices(pack), (p) => p.amount);

  const handleHeadcountChange = (value: number | undefined) => {
    setHeadcountErr(null);
    setHeadcount(value);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!headcount) {
      headcountRef.current?.focus();
      setHeadcountErr('Please enter your headcount');
      return;
    }
    if (!email) {
      emailRef.current?.focus();
      setEmailErr('Please enter your email');
      return;
    }
    if (!EMAIL_PATTERN.test(email)) {
      emailRef.current?.focus();
      setEmailErr('Please enter a valid email');
      return;
    }

    try {
      await apiService.auth.checkEmailAvailability({
        email,
      });
    } catch (error) {
      const apiErr = apiService.utils.CastAPIError(error);
      if (apiErr?.msg === 'free_email_address') {
        emailRef.current?.focus();
        setEmailErr('Please enter your work email address');
        return;
      }
    }

    analytics.trackLibraryGameDetailsClicked(
      pack.id,
      pack.name,
      'See Instant Quote'
    );

    if (GamePackUtils.IsLive(pack) && headcount <= 10) {
      setShowOnDForSmallGroups(true);
      return;
    }

    hubspotTrackInstantQuoteFormSubmitted({
      email,
      headcount,
      kind: 'gamePack',
      gamePackName: pack.name,
    });

    const params = new URLSearchParams(window.location.search);
    params.set('pack-id', pack.id);
    params.set('email', email);
    params.set('headcount', headcount.toString());
    params.set('register-for', 'oneTimePurchase');
    navigate(`/register?${params.toString()}`);
  };

  return (
    <div className='relative w-full flex flex-col items-center gap-4'>
      <div className='absolute -top-3.5'>
        {freeTrialAvailable ? (
          <div className='w-45 h-7 rounded-2.5xl bg-green-001 flex justify-center items-center text-white italic font-bold'>
            Free Trial Available
          </div>
        ) : (
          <div className='w-45 h-7 rounded-2.5xl bg-white flex justify-center items-center text-black italic'>
            Starting at {formatCurrency(lowestPrice?.amount ?? 0)} USD
          </div>
        )}
      </div>

      {showOnDForSmallGroups ? (
        <div className='w-full bg-main-layer rounded-xl p-2 pt-7 flex flex-col gap-4'>
          <p className='text-center text-xl font-bold text-tertiary'>
            Check Out Our On <br />
            Demand Library
          </p>
          <p className='text-base px-4'>
            For your group size, we recommend our library of 100s of On Demand
            events ! 🙌
          </p>
          <Link
            to={$path('/explore')}
            className='btn-delete rounded w-full h-15 flex items-center justify-center font-bold'
          >
            See Events for Small Groups
          </Link>
        </div>
      ) : (
        <form
          id='get-instant-quote-form'
          onSubmit={handleSubmit}
          className='w-full bg-main-layer rounded-xl p-2 pt-7'
        >
          <p className='text-center text-xl font-bold text-tertiary'>
            {isGamePackLaunched(pack)
              ? 'Get An Instant Quote'
              : `Launching ${format(
                  new Date(pack.detailSettings?.availability?.launchDate ?? ''),
                  'M/d/yy'
                )}`}
          </p>

          <div className='mt-7 w-full flex flex-col gap-2.5'>
            <div className='flex flex-col items-center justify-center gap-2.5 px-2 pb-3'>
              {/* 
                This is a workaround to prevent the form from being collected by Hubspot.
                Refer to https://community.hubspot.com/t5/Lead-Capture-Tools/Ignore-a-specific-non-Hubspot-form-from-being-collected/m-p/246957
              */}
              <label htmlFor='cc-num' className='hidden'></label>
              <input
                name='cc-num'
                className='hidden'
                defaultValue='HubspotCollectedFormsWorkaround'
                id='cc-num'
              />

              <div className='w-full bg-layer-002 border border-secondary rounded-xl pl-4 p-3 flex items-center gap-2'>
                <div className='flex-1 flex flex-col gap-1 text-white'>
                  <div className='font-bold'>Headcount</div>
                  <div className='text-2xs font-light'>
                    You can Increase the team size later!
                  </div>
                </div>

                <div className='flex-none w-34 flex flex-col items-end gap-1'>
                  <input
                    name='headcount'
                    type='number'
                    value={headcount || ''}
                    onChange={(e) =>
                      handleHeadcountChange(e.currentTarget.valueAsNumber)
                    }
                    className={`
                     w-20 text-xl font-bold text-center m-0 p-0 transition-colors
                    ${headcountErr ? 'field-error' : 'field'}
                  `}
                    placeholder={'0'}
                    ref={headcountRef}
                  />
                  <div className='text-red-006 text-3xs h-3 whitespace-nowrap'>
                    {headcountErr}
                  </div>
                </div>
              </div>

              <div className='w-full p-2 bg-layer-002 border border-secondary rounded-xl flex flex-col gap-1.5'>
                <div className='flex-1 text-white px-2'>
                  <div className='font-bold'>Email</div>
                </div>

                <input
                  ref={emailRef}
                  name='email'
                  type='email'
                  value={email}
                  onChange={(e) => {
                    setEmail(e.currentTarget.value);
                    setEmailErr(null);
                  }}
                  className={`w-full h-12.5 m-0 transition-colors ${
                    emailErr ? 'field-error' : 'field'
                  }`}
                  placeholder='Enter your work email address'
                />

                {emailErr ? (
                  <div className='px-2 text-red-006 text-3xs h-3 whitespace-nowrap'>
                    {emailErr}
                  </div>
                ) : null}
              </div>

              <div className='w-full flex flex-col justify-center gap-3 pt-2.5'>
                <button
                  type='submit'
                  className='btn-delete rounded w-full h-15 flex items-center justify-center font-bold'
                >
                  👉 See Instant Quote
                </button>
              </div>
            </div>
          </div>
        </form>
      )}

      <div className='flex flex-col gap-4'>
        <div className='text-sms text-icon-gray italic'>
          Trusted by 90,000+ customers in 100+ countries
        </div>
        <div className='flex items-center justify-between'>
          <img
            src={getStaticAssetPath('images/onboarding/microsoft-v2.png')}
            alt='microsoft'
            className='w-15'
          />
          <img
            src={getStaticAssetPath('images/onboarding/stripe-v2.png')}
            alt='stripe'
            className='w-10.5'
          />
          <img
            src={getStaticAssetPath('images/onboarding/spotify-v2.png')}
            alt='spotify'
            className='w-12.5'
          />
          <img
            src={getStaticAssetPath('images/onboarding/meta-v2.png')}
            alt='meta'
            className='w-12.5'
          />
          <img
            src={getStaticAssetPath('images/onboarding/netflix-v3.png')}
            alt='netflix'
            className='w-11'
          />
        </div>
      </div>
    </div>
  );
}

function SubscribeToProduct(props: {
  product: DtoProduct;
  onClick?: () => void;
}) {
  const { product } = props;
  const prices = ProductUtils.ActivePrices(product);
  const price = prices.sort((a, b) => a.amount - b.amount)[0];
  const navigate = useNavigate();

  const features = useInstance(() => [
    'Unlimited Access to the Library',
    'DEI Experiences',
    'Ice Breakers',
    'Seasonal Celebrations',
    'And so much more',
  ]);

  if (!price) return null;

  const handleContinue = () => {
    props.onClick?.();
    const params = new URLSearchParams(window.location.search);
    params.set('product-id', product.id);
    params.set('price-id', price.id);
    navigate(`/register?${params.toString()}`);
  };

  return (
    <div className='w-full flex flex-col items-center gap-2'>
      <div
        className='rounded-xl p-px'
        style={{
          background:
            'linear-gradient(225deg, rgba(255,255,255,0.6), rgba(254,6,83,0.6))',
        }}
      >
        <div
          className='w-full rounded-xl p-2 pt-7'
          style={{
            background:
              'linear-gradient(180deg, rgba(255, 9, 53, 0.40) 13.5%, rgba(27, 27, 30, 0.40) 49.05%), #1B1B1E',
          }}
        >
          <p className='text-center text-xl font-bold text-tertiary'>
            Become A Member
          </p>
          <ul className='flex flex-col gap-2.5 text-sms m-7.5'>
            {features.map((feature, i) => (
              <li key={i} className='flex items-center gap-1'>
                <GreenCheckIcon className='w-3.5 h-3.5' />
                <p>{feature}</p>
              </li>
            ))}
          </ul>
          <div className='w-full flex items-baseline justify-center text-xl'>
            <span className='font-medium'>
              {formatCurrency(price.amount)} USD
            </span>
            <span className='text-xs'>
              /{ProductUtils.FormatInterval(price.billingInterval, true)}
            </span>
          </div>

          <div className='w-full text-center text-xs font-light my-3'>
            This experience is only available to members
          </div>

          <button
            type='button'
            className='btn-delete rounded w-full h-15 flex items-center justify-center font-medium'
            onClick={handleContinue}
          >
            Get Started
          </button>

          <div className='w-full text-center text-3xs font-light my-3'>
            You’ll need to login on the next page to complete booking
          </div>
        </div>
      </div>
    </div>
  );
}

export function GamePackDetailsSideAction(props: {
  pack: GamePack;
  product: DtoProduct | null | undefined;
}) {
  const { pack, product } = props;

  const featureChecker = useAnonFeatureChecker();
  const canPurchase = featureChecker.canPurchaseGamePack(pack);
  const analytics = useAnonAppAnalytics();

  if (canPurchase) {
    if (GamePackUtils.IsLive(pack)) return <GetInstantQuoteLive pack={pack} />;
    return <GetInstantQuote pack={pack} />;
  }
  if (product)
    return (
      <SubscribeToProduct
        product={product}
        onClick={() => {
          analytics.trackLibraryGameDetailsClicked(
            pack.id,
            pack.name,
            'Get Started'
          );
        }}
      />
    );

  return null;
}
